import React from 'react'
import PropTypes from 'prop-types'
import { IconCaretRight } from 'vtex.styleguide'
import { FormattedMessage } from 'react-intl'

import HorizontalSeparator from './shared/HorizontalSeparator'

const PasswordCreationChoice = ({
  onChooseContinueWithPassword,
  onChooseContinueWithToken,
}) => (
  <React.Fragment>
    <div>
      <div className="t-heading-4 c-on-base mb5">
        <FormattedMessage id="admin/createPasswordDialog.wouldYouLikeToCreateAPassword" />
      </div>
    </div>
    <div className="mt7 mb5">
      <HorizontalSeparator />
      <div
        className="c-action-primary pv6 flex flex-column justify-center link pointer nh8 ph8 hover-bg-near-white"
        data-testid="continue-with-password"
        onClick={onChooseContinueWithPassword}>
        <div className="flex items-center justify-between">
          <div>
            <FormattedMessage id="admin/createPasswordDialog.createPassword" />{' '}
            <div className="t-body-ns t-small c-muted-1 lh-copy">
              <FormattedMessage id="admin/createPasswordDialog.createPasswordExplanation" />
            </div>
          </div>
          <div className="h-100 flex flex-column justify-center">
            <IconCaretRight size={14} />
          </div>{' '}
        </div>
      </div>
      <HorizontalSeparator />
      <div
        className="c-action-primary pv6 flex flex-column justify-center link pointer nh8 ph8 hover-bg-near-white"
        data-testid="continue-with-token"
        onClick={onChooseContinueWithToken}>
        <div className="flex justify-between">
          <div>
            <FormattedMessage id="admin/createPasswordDialog.continueWithoutAPassword" />
            <div className="t-body-ns t-small c-muted-1 lh-copy">
              <FormattedMessage id="admin/createPasswordDialog.continueWithoutAPasswordExplanation" />
            </div>
          </div>
          <div className="h-100 flex flex-column justify-center">
            <IconCaretRight size={14} />
          </div>{' '}
        </div>
      </div>
      <HorizontalSeparator />
    </div>
  </React.Fragment>
)

PasswordCreationChoice.propTypes = {
  onChooseContinueWithPassword: PropTypes.func.isRequired,
  onChooseContinueWithToken: PropTypes.func.isRequired,
}

export default PasswordCreationChoice
