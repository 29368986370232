import React, { useState, useCallback, useEffect } from 'react'
import { interpret } from 'xstate/lib/interpreter'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import {
  sendForbiddenNavigationMetric,
  sendTimeToLoginMetric,
  sendStateDurationMetric,
  sendLoginStartedMetric,
} from '../../commons/rc'
import loginMachine from './loginMachine'

const getCurrentTime = () => new Date().getTime()

const finalStates = ['redirecting', 'saml_redirecting', 'oauth_redirecting']

const generateTransitionHandlers = (
  sendEvent,
  transitions,
  beforeTransitionCallback = null
) => {
  return Object.keys(transitions).reduce(
    (acc, key) => ({
      ...acc,
      [key]: ({ details } = {}) => {
        if (typeof beforeTransitionCallback === 'function') {
          beforeTransitionCallback()
        }
        sendEvent({
          ...(details && { details }),
          type: transitions[key],
        })
      },
    }),
    {}
  )
}

const getStateFromMachine = machine =>
  machine &&
  machine.state &&
  typeof machine.state.toStrings === 'function' &&
  machine.state.toStrings().pop()

const StateMachine = ({
  isUserAuthenticated,
  isUserIdentified,
  identityProviders,
  transitionsMapping,
  onStateChange,
  children,
  onError,
  onClearError,
}) => {
  const [currentState, setCurrentState] = useState(
    loginMachine.initialState.toStrings().pop()
  )

  const [loginMachineService] = useState(() => {
    const service = interpret(
      loginMachine.withContext({
        isUserIdentified,
        isUserAuthenticated,
        identityProviders,
        userHasTwoFactor: false,
        twoFactorType: '',
      }),
      { devTools: true }
    ).onTransition(current => setCurrentState(current.toStrings().pop()))

    service.start()

    return service
  })

  const [history, setHistory] = useState([])
  const logNavigation = useCallback(navigation =>
    setHistory([...history, navigation])
  )
  const [navigationStartTime] = useState(getCurrentTime())
  const [lastStateTime, setLastStateTime] = useState(null)

  useEffect(() => {
    sendLoginStartedMetric({ state: currentState })
  }, [currentState])

  const sendEvent = useCallback(event => {
    const previousState = getStateFromMachine(loginMachineService)

    loginMachineService.send(event)

    const newState = getStateFromMachine(loginMachineService)

    if (previousState !== newState) {
      const secondsSince = start => (getCurrentTime() - start) / 1000

      const totalTimeElapsed = secondsSince(navigationStartTime)

      const timeElapsed = lastStateTime
        ? secondsSince(lastStateTime)
        : totalTimeElapsed

      setLastStateTime(getCurrentTime())

      logNavigation(newState)
      onClearError()

      sendStateDurationMetric({
        value: timeElapsed,
        state: previousState,
        nextState: newState,
      })

      if (finalStates.includes(newState)) {
        const provider = event && event.details && event.details.provider
        sendTimeToLoginMetric({
          value: totalTimeElapsed,
          path: history.join('/'),
          provider,
        })
      }
    } else {
      onError(<FormattedMessage id="admin/error.UnexpectedError" />)
      sendForbiddenNavigationMetric({
        event: (event && event.type) || JSON.stringify(event),
        path: history.join('/'),
      })
    }
  })

  return (
    <React.Fragment>
      {children({
        sendEvent,
        state: currentState,
        transitionHandlers: generateTransitionHandlers(
          sendEvent,
          transitionsMapping,
          onStateChange
        ),
      })}
    </React.Fragment>
  )
}

StateMachine.propTypes = {
  children: PropTypes.any,
  isUserAuthenticated: PropTypes.bool,
  isUserIdentified: PropTypes.bool,
  transitionsMapping: PropTypes.object,
  identityProviders: PropTypes.object,
  onStateChange: PropTypes.func,
  onError: PropTypes.func,
  onClearError: PropTypes.func,
  userId: PropTypes.string,
}

export default StateMachine
