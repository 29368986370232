import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { IconPlusLines } from 'vtex.styleguide'

import { NOOP } from '../../commons/constants'

const ENTER_KEY_CODE = 13

const AppendableInput = ({
  value = '',
  onChange = NOOP,
  onEnter = NOOP,
  prefix = null,
  appendedLeft = false,
  appendedRight = false,
  borderDanger = false,
}) => {
  const [focus, setFocus] = useState(false)

  const handleKey = useCallback(
    e => {
      if ((e.charCode || e.keyCode) === ENTER_KEY_CODE) {
        e.preventDefault()
        return onEnter()
      }
    },
    [onEnter]
  )

  const handleFocus = useCallback(() => setFocus(true), [])

  const handleBlur = useCallback(() => setFocus(false), [])

  const borderColorClasses = useMemo(() => {
    if (borderDanger) {
      return 'b--danger hover-b--danger'
    }
    return focus ? 'b--muted-2' : 'hover-b--muted-3 b--muted-4'
  }, [borderDanger, focus])

  return (
    <label
      className={`${borderColorClasses} br2 bw1 b--solid ${
        appendedLeft ? 'br--right' : ''
      } ${appendedRight ? 'br--left' : ''} ph5 flex`}
      style={{
        ...(appendedLeft && { borderLeftWidth: '0.0625rem' }),
        ...(appendedRight && { borderRightWidth: '0.0625rem' }),
      }}>
      {prefix && <div className="flex items-center pr4">{prefix}</div>}
      <input
        className={`outline-transparent bn h-large w-100`}
        value={value}
        onChange={onChange}
        onKeyDown={handleKey}
        onKeyPress={handleKey}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </label>
  )
}

AppendableInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  prefix: PropTypes.node,
  appendedLeft: PropTypes.bool,
  appendedRight: PropTypes.bool,
  borderDanger: PropTypes.bool,
}

const PhoneInput = ({
  label = '',
  onEnter = NOOP,
  valueCountryCode = '',
  onChangeCountryCode = NOOP,
  valuePhone = '',
  onChangePhone = NOOP,
  errorCountryCode = false,
  errorPhone = false,
  errorMessage = '',
}) => {
  const handleChangeCountryCode = useCallback(
    e => onChangeCountryCode(e.target.value),
    [onChangeCountryCode]
  )

  const handleChangePhone = useCallback(e => onChangePhone(e.target.value), [
    onChangePhone,
  ])

  return (
    <>
      {label && <div className="mb4">{label}</div>}
      <div className="flex">
        <div className="w-30" data-testid="countryCodeInput">
          <AppendableInput
            appendedRight
            type="tel"
            id="requestphone_countryCode"
            name="countryCode"
            onEnter={onEnter}
            value={valueCountryCode}
            onChange={handleChangeCountryCode}
            borderDanger={errorCountryCode}
            prefix={
              <span className="c-on-base" style={{ marginRight: '-0.5rem' }}>
                <IconPlusLines size={11} />
              </span>
            }
          />
        </div>
        <div className="w-70" data-testid="phoneInput">
          <AppendableInput
            appendedLeft
            type="tel"
            id="requestphone_phone"
            name="phone"
            onEnter={onEnter}
            value={valuePhone}
            onChange={handleChangePhone}
            borderDanger={errorPhone}
          />
        </div>
      </div>
      {errorMessage && (
        <div className="c-danger mt3 t-small lh-title">{errorMessage}</div>
      )}
    </>
  )
}

PhoneInput.propTypes = {
  label: PropTypes.node,
  onEnter: PropTypes.func,
  valueCountryCode: PropTypes.string,
  onChangeCountryCode: PropTypes.func,
  valuePhone: PropTypes.string,
  onChangePhone: PropTypes.func,
  errorCountryCode: PropTypes.bool,
  errorPhone: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export default PhoneInput
