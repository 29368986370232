import React from 'react'
import { FormattedMessage } from 'react-intl'

import GoogleIcon from '../icons/GoogleIcon'
import ProviderButton from './ProviderButton'

const GoogleSignInButton = props => (
  <ProviderButton icon={GoogleIcon} {...props}>
    <FormattedMessage id="admin/googleSignInButton" />
  </ProviderButton>
)

export default GoogleSignInButton
