import { useCallback } from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { validations } from 'vtex.react-vtexid'

const messages = defineMessages({
  hasNumber: {
    id: 'admin/passwordLogin.error.hasNumber',
    defaultMessage: '',
  },
  hasMinLength: {
    id: 'admin/passwordLogin.error.hasMinLength',
    defaultMessage: '',
  },
  hasLowerCaseLetter: {
    id: 'admin/passwordLogin.error.hasLowerCaseLetter',
    defaultMessage: '',
  },
  hasUpperCaseLetter: {
    id: 'admin/passwordLogin.error.hasUpperCaseLetter',
    defaultMessage: '',
  },
  hasLetter: {
    id: 'admin/passwordLogin.error.hasLetter',
    defaultMessage: '',
  },
})

const useGetPasswordErrors = () => {
  const { formatMessage } = useIntl()

  const getPasswordErrors = useCallback(
    password => {
      const validated = validations.validatePassword(password)

      return Object.keys(validated)
        .filter(key => !validated[key] && messages[key])
        .map(key => formatMessage(messages[key]))
    },
    [formatMessage]
  )
  return getPasswordErrors
}

export default useGetPasswordErrors
