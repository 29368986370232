import React from 'react'
import PropTypes from 'prop-types'
import { AuthState } from 'vtex.react-vtexid'

import TokenScreen from './TokenScreen'

const TokenLogin = ({
  onLoggedWithToken,
  onChangeEmail,
  onSwitchToPassword,
  setAlertError,
}) => (
  <div data-testid="token-login">
    <AuthState.IdentityProviders>
      {({ value: { password } }) => (
        <TokenScreen
          onTokenConfirmed={onLoggedWithToken}
          onChangeEmail={onChangeEmail}
          onSwitchToPassword={onSwitchToPassword}
          setAlertError={setAlertError}
          canSetPassword={password}
        />
      )}
    </AuthState.IdentityProviders>
  </div>
)

TokenLogin.propTypes = {
  onLoggedWithToken: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onSwitchToPassword: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
}

export default TokenLogin
