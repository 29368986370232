import { useCallback, useRef } from 'react'

import { NOOP } from '../../commons/constants'

const useTimer = ({ onFinish = NOOP } = {}) => {
  const timerStateRef = useRef({
    timerEnd: 0,
    timeoutId: null,
  })

  const start = useCallback(
    seconds => {
      const miliseconds = 1000 * seconds
      timerStateRef.current.timerEnd = Date.now() + miliseconds
      clearTimeout(timerStateRef.current.timeoutId)
      timerStateRef.current.timeoutId = setTimeout(onFinish, miliseconds)
    },
    [onFinish]
  )

  const getSecsLeft = useCallback(() => {
    const { timerEnd } = timerStateRef.current
    const now = Date.now()
    if (timerEnd <= now) {
      return 0
    }
    return (timerEnd - now) / 1000
  }, [])

  return [start, getSecsLeft]
}

export default useTimer
