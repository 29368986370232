import RequestEmail from '../RequestEmail/index.js'
import Redirect from '../Redirect'
import OauthRedirect from '../OauthRedirect'
import SamlRedirect from '../SamlRedirect'
import CachedUsers from '../CachedUsers'
import RequestPassword from '../RequestPassword'
import TokenConfirmation from '../TokenConfirmation'
import TokenLogin from '../TokenLogin'
import PasswordCreationChoice from '../PasswordCreationChoice'
import SetPassword from '../setpassword/SetPassword'
import SetForgotPassword from '../setForgotPassword/SetForgotPassword'
import SavedUsersAdmin from '../SavedUsersAdmin'
import UpdateExpiredPassword from '../updateExpiredPassword/UpdateExpiredPassword'
import MfaOptions from '../mfa/MfaOptions'
import GetAuthApp from '../mfa/GetAuthApp'
import SetUpAuthApp from '../mfa/SetUpAuthApp'
import AuthAppVerification from '../mfa/AuthAppVerification'
import RequestPhone from '../mfa/RequestPhone'
import RequestSmsCode from '../mfa/RequestSmsCode'

const componentByState = {
  'identification.identified_users': CachedUsers,
  'identification.unidentified_user': RequestEmail,
  user_managing: SavedUsersAdmin,
  password_login: RequestPassword,
  token_login: TokenLogin,
  'default_login.token_confirmation': TokenConfirmation,
  'default_login.choose_action': PasswordCreationChoice,
  set_password: SetPassword,
  set_forgot_password: SetForgotPassword,
  update_expired_password: UpdateExpiredPassword,
  'two_factor.setup.options_screen': MfaOptions,
  'two_factor.setup.add_phone': RequestPhone,
  'two_factor.setup.get_app': GetAuthApp,
  'two_factor.setup.qrcode': SetUpAuthApp,
  'two_factor.validate_app': AuthAppVerification,
  'two_factor.validate_sms': RequestSmsCode,
  redirecting: Redirect,
  oauth_redirecting: OauthRedirect,
  saml_redirecting: SamlRedirect,
}

export default componentByState
