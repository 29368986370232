import React from 'react'
import { FormattedMessage } from 'react-intl'
import { IconSuccess } from 'vtex.styleguide'

const Requirement = ({ condition, message }) => {
  const iconStyle = condition ? 'c-success mr4' : 'c-muted-3 mr4'

  return (
    condition !== undefined && (
      <div className="dib w-50-ns w-100 mb4">
        <span className={iconStyle}>
          <IconSuccess solid color="currentColor" />
        </span>
        <span className="v-top">{message}</span>
      </div>
    )
  )
}

Requirement.propTypes = {
  condition: PropTypes.boolean | PropTypes.undefined,
  message: PropTypes.node,
}

const PasswordRequirementIndicator = ({
  hasEightCharacters,
  hasNumber,
  hasUppercaseLetter,
  hasLowercaseLetter,
  hasLetter,
}) => {
  return (
    <div>
      <div className="br2 w-100 ph0 pt4 dib c-muted-1  t-small">
        <div className="mb5">
          <span>
            <FormattedMessage id="admin/setPassword.passwordRequirements.title" />
          </span>
        </div>
        <div className="w-100">
          <Requirement
            condition={hasEightCharacters}
            message={
              <FormattedMessage id="admin/setPassword.passwordRequirements.8characters" />
            }
          />
          <Requirement
            condition={hasNumber}
            message={
              <FormattedMessage id="admin/setPassword.passwordRequirements.1number" />
            }
          />
          <Requirement
            condition={hasUppercaseLetter}
            message={
              <FormattedMessage id="admin/setPassword.passwordRequirements.1uppercase" />
            }
          />
          <Requirement
            condition={hasLowercaseLetter}
            message={
              <FormattedMessage id="admin/setPassword.passwordRequirements.1lowercase" />
            }
          />
          <Requirement
            condition={hasLetter}
            message={
              <FormattedMessage id="admin/setPassword.passwordRequirements.1letter" />
            }
          />
        </div>
      </div>
    </div>
  )
}

PasswordRequirementIndicator.propTypes = {
  hasEightCharacters: PropTypes.boolean,
  hasNumber: PropTypes.boolean,
  hasUppercaseLetter: PropTypes.boolean | PropTypes.undefined,
  hasLowercaseLetter: PropTypes.boolean | PropTypes.undefined,
  hasLetter: PropTypes.boolean | PropTypes.undefined,
}

export default PasswordRequirementIndicator
