import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { calcIconSize } from '../utils/icons'

const iconBase = {
  width: 19,
  height: 20,
}

export default class GooglePlayIcon extends PureComponent {
  static defaultProps = {
    color: 'currentColor',
    size: 20,
  }

  static propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
  }

  render() {
    const { color, size } = this.props
    const newSize = calcIconSize(iconBase, size)

    return (
      <span className="c-on-muted-3">
        <svg
          width={newSize.width}
          height={newSize.height}
          viewBox="0 0 19 20"
          style={{ fill: color }}
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.364847 0.308756C0.133744 0.547862 0 0.920127 0 1.40223V18.5984C0 19.0805 0.133744 19.4528 0.364847 19.6919L0.421885 19.7444L10.1715 10.1121V9.88465L0.421885 0.252383L0.364847 0.308756Z"
            fill="url(#paint0_linear)"
          />
          <path
            d="M13.4179 13.3244L10.1716 10.112V9.88458L13.4218 6.67221L13.4946 6.71401L17.3437 8.87859C18.4422 9.49288 18.4422 10.5037 17.3437 11.1219L13.4946 13.2826L13.4179 13.3244Z"
            fill="url(#paint1_linear)"
          />
          <path
            d="M13.4946 13.2826L10.1716 9.99833L0.36499 19.6918C0.729838 20.0709 1.3248 20.1166 2.00139 19.7375L13.4946 13.2826Z"
            fill="url(#paint2_linear)"
          />
          <path
            d="M13.4946 6.71408L2.00139 0.259201C1.3248 -0.115982 0.729838 -0.0703004 0.36499 0.30877L10.1716 9.99838L13.4946 6.71408Z"
            fill="url(#paint3_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="9.30583"
              y1="18.7775"
              x2="-3.74293"
              y2="5.57511"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#00A0FF" />
              <stop offset="0.0066" stopColor="#00A1FF" />
              <stop offset="0.2601" stopColor="#00BEFF" />
              <stop offset="0.5122" stopColor="#00D2FF" />
              <stop offset="0.7604" stopColor="#00DFFF" />
              <stop offset="1" stopColor="#00E3FF" />
            </linearGradient>
            <linearGradient
              id="paint1_linear"
              x1="18.7741"
              y1="9.99722"
              x2="-0.262411"
              y2="9.99722"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFE000" />
              <stop offset="0.4087" stopColor="#FFBD00" />
              <stop offset="0.7754" stopColor="#FFA500" />
              <stop offset="1" stopColor="#FF9C00" />
            </linearGradient>
            <linearGradient
              id="paint2_linear"
              x1="11.6876"
              y1="8.21293"
              x2="-6.00752"
              y2="-9.69054"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF3A44" />
              <stop offset="1" stopColor="#C31162" />
            </linearGradient>
            <linearGradient
              id="paint3_linear"
              x1="-2.10354"
              y1="25.413"
              x2="5.79815"
              y2="17.4183"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#32A071" />
              <stop offset="0.0685" stopColor="#2DA771" />
              <stop offset="0.4762" stopColor="#15CF74" />
              <stop offset="0.8009" stopColor="#06E775" />
              <stop offset="1" stopColor="#00F076" />
            </linearGradient>
          </defs>
        </svg>
      </span>
    )
  }
}
