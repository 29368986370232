import { assign } from 'xstate'

const twoFactorMachine = {
  two_factor: {
    id: 'two_factor',
    onEntry: ['checkTwoFactorSettings'],
    initial: 'unknown',
    states: {
      unknown: {
        on: {
          '': [
            { target: 'setup', cond: 'needsToSetupTwoFactor' },
            {
              target: 'validate_sms',
              cond: 'hasSmsTwoFactor',
            },
            { target: 'validate_app' },
          ],
        },
      },
      setup: {
        id: 'two_factor_setup',
        initial: 'options_screen',
        states: {
          options_screen: {
            on: {
              CHOOSE_SMS: 'add_phone',
              CHOOSE_APP: 'get_app',
            },
          },
          add_phone: {
            on: {
              SMS_SET_UP: '#validate_sms',
            },
          },
          qrcode: {
            on: {
              APP_SET_UP: '#validate_app',
            },
          },
          get_app: {
            on: {
              CONTINUE: 'qrcode',
            },
          },
        },
      },
      validate_app: {
        id: 'validate_app',
        on: {
          APP_VERIFICATION_DONE: '#redirecting',
        },
      },
      validate_sms: {
        id: 'validate_sms',
        on: {
          SMS_VERIFICATION_DONE: '#redirecting',
        },
      },
    },
  },
}

const twoFactorGuards = {
  needsToSetupTwoFactor: ({ userHasTwoFactor } = {}) => !userHasTwoFactor,
  hasSmsTwoFactor: ({ twoFactorType }) => twoFactorType === 'SMS',
}

const twoFactorActions = {
  checkTwoFactorSettings: assign((ctx, { details } = {}) => {
    return {
      userHasTwoFactor: details && details.userHasTwoFactor,
      twoFactorType: details && details.twoFactorType,
    }
  }),
}

export { twoFactorMachine, twoFactorGuards, twoFactorActions }
