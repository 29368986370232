import React from 'react'
import { AuthState, AuthService } from 'vtex.react-vtexid'
import { Button, Spinner } from 'vtex.styleguide'
import PropTypes from 'prop-types'
import { BooleanValue, Value } from 'react-values'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import Recaptcha from 'react-google-recaptcha'

import { TabbableInput } from './shared'
import joinErrorMessages from './utils/joinErrorMessages'
import { RECAPTCHA_SITEKEY } from '../commons/constants'
import useGetPasswordErrors from './utils/useGetPasswordErrors'
import getIntlError from './utils/getIntlError'

const messages = defineMessages({
  separator: {
    id: 'admin/passwordLogin.separator',
    defaultMessage: '',
  },
  lastSeparator: {
    id: 'admin/passwordLogin.lastSeparator',
    defaultMessage: '',
  },
})
const recaptchaRef = React.createRef()

const RequestPassword = ({
  onLoggedWithPassword,
  onPasswordExpired,
  onForgotPassword,
  onTwoFactorRequired,
  onBack,
  setAlertError,
}) => {
  const { formatMessage } = useIntl()
  const getPasswordErrors = useGetPasswordErrors()

  return (
    <div data-testid="password-login">
      <AuthState.Email>
        {({ value: email }) => (
          <div className="mb7">
            <div className="t-heading-4 c-on-base mb5">
              <FormattedMessage id="admin/passwordLogin.enterPassword" />
            </div>
            <div className="t-body-ns t-small c-on-base">{email}</div>
            <div className="flex mb4 mt5 t-small">
              <a
                data-testid="back"
                className="link c-link pointer t-small"
                onClick={onBack}>
                <FormattedMessage id="admin/tokenConfirmation.changeEmail" />
              </a>
            </div>
          </div>
        )}
      </AuthState.Email>
      <BooleanValue>
        {({ value: hasRecaptchaError, set: setRecaptchaError }) => (
          <BooleanValue>
            {({ value: isRecaptchaVisible, set: setRecaptchaVisibility }) => (
              <Value defaultValue="">
                {({
                  value: errorMessage,
                  set: setErrorMessage,
                  reset: removeErrorMessage,
                }) => (
                  <AuthState.Recaptcha>
                    {({ setValue: handleRecaptchaChange }) => (
                      <AuthState.Password>
                        {({
                          value: password,
                          setValue: handlePasswordChange,
                          validation: { validatePassword },
                        }) => {
                          const { passwordIsValid } = validatePassword(
                            password || ''
                          )
                          return (
                            <AuthService.LoginWithPassword
                              useNewSession
                              saveUserAccount
                              onSuccess={onLoggedWithPassword}
                              onRequiresPasswordUpdate={onPasswordExpired}
                              onRequiresSmsMfa={() =>
                                onTwoFactorRequired({
                                  details: {
                                    userHasTwoFactor: true,
                                    twoFactorType: 'SMS',
                                  },
                                })
                              }
                              onRequiresAppMfa={() =>
                                onTwoFactorRequired({
                                  details: {
                                    userHasTwoFactor: true,
                                    twoFactorType: 'AUTH_APP',
                                  },
                                })
                              }
                              onRequiresMfaRegistration={() =>
                                onTwoFactorRequired({
                                  details: {
                                    userHasTwoFactor: false,
                                  },
                                })
                              }
                              onFailure={error => {
                                if (recaptchaRef && recaptchaRef.current) {
                                  recaptchaRef.current.reset()
                                }

                                if (error.code === 'WrongCredentials') {
                                  setErrorMessage(
                                    <FormattedMessage id="admin/passwordLogin.incorrectPassword" />
                                  )
                                } else if (
                                  ['BlockedUser', 'InvalidRecaptcha'].includes(
                                    error.code
                                  )
                                ) {
                                  if (isRecaptchaVisible) {
                                    setRecaptchaError(true)
                                  } else {
                                    setRecaptchaVisibility(true)
                                  }
                                } else {
                                  setAlertError(getIntlError(error.code))
                                }
                              }}>
                              {({ loading, action: loginWithPassword }) => {
                                const tryLogin = () => {
                                  setRecaptchaError(false)
                                  removeErrorMessage()
                                  if (password && passwordIsValid) {
                                    loginWithPassword()
                                  } else {
                                    setErrorMessage(
                                      <FormattedMessage
                                        id="admin/passwordLogin.inlinePasswordError"
                                        values={{
                                          errors: joinErrorMessages(
                                            getPasswordErrors(password),
                                            formatMessage(messages.separator),
                                            formatMessage(
                                              messages.lastSeparator
                                            )
                                          ),
                                        }}
                                      />
                                    )
                                  }
                                }
                                return (
                                  <React.Fragment>
                                    <div
                                      className="mb5"
                                      data-testid="password-input">
                                      <TabbableInput
                                        autoFocus
                                        size="large"
                                        type="password"
                                        label={
                                          <FormattedMessage id="admin/passwordLogin.inputLabel.password" />
                                        }
                                        name="password"
                                        value={password || ''}
                                        onChange={e => {
                                          removeErrorMessage()
                                          handlePasswordChange(e.target.value)
                                        }}
                                        onTab={tryLogin}
                                        onEnter={tryLogin}
                                        errorMessage={errorMessage}
                                      />
                                    </div>
                                    <div>
                                      {isRecaptchaVisible && (
                                        <div className="mb5">
                                          <div className="mb5 mt3 t-body-ns t-small c-on-base">
                                            <FormattedMessage id="admin/recaptcha.explanation" />
                                          </div>
                                          <Recaptcha
                                            sitekey={RECAPTCHA_SITEKEY}
                                            ref={recaptchaRef}
                                            onChange={newRecaptcha => {
                                              setRecaptchaError(false)
                                              handleRecaptchaChange(
                                                newRecaptcha
                                              )
                                            }}
                                          />
                                          {hasRecaptchaError && (
                                            <div className="c-danger t-small mt3 lh-title">
                                              <FormattedMessage id="admin/passwordLogin.recaptchaMissing" />
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      <Button
                                        id="chooseprovider_signinbtn"
                                        variation="primary"
                                        block
                                        isLoading={loading}
                                        onClick={tryLogin}>
                                        <div data-testid="request-password-continue">
                                          <FormattedMessage id="admin/button.continue" />
                                        </div>
                                      </Button>
                                    </div>
                                    <div className="dib w-100 mt6 mb7 flex justify-center">
                                      <AuthService.SendAccessKey
                                        useNewSession
                                        saveUserAccount
                                        onSuccess={onForgotPassword}
                                        onFailure={() =>
                                          setAlertError(
                                            <FormattedMessage id="admin/passwordLogin.sendTokenError" />
                                          )
                                        }>
                                        {({ loading, action: sendToken }) => {
                                          if (loading) {
                                            return <Spinner size={16} />
                                          }
                                          return (
                                            <a
                                              data-testid="forgot-password"
                                              onClick={sendToken}
                                              className="fr link c-link f6 pointer">
                                              <FormattedMessage id="admin/passwordLogin.forgotPassword" />
                                            </a>
                                          )
                                        }}
                                      </AuthService.SendAccessKey>
                                    </div>
                                  </React.Fragment>
                                )
                              }}
                            </AuthService.LoginWithPassword>
                          )
                        }}
                      </AuthState.Password>
                    )}
                  </AuthState.Recaptcha>
                )}
              </Value>
            )}
          </BooleanValue>
        )}
      </BooleanValue>
    </div>
  )
}

RequestPassword.propTypes = {
  onLoggedWithPassword: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onPasswordExpired: PropTypes.func.isRequired,
  onForgotPassword: PropTypes.func.isRequired,
  onTwoFactorRequired: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
}

export default RequestPassword
