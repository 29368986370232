import React from 'react'
import { IconCopy, ToastConsumer } from 'vtex.styleguide'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { TOAST_DURATION } from '../commons/constants'

class CopyOnlyInput extends React.Component {
  constructor(props) {
    super(props)
    this.input = React.createRef()
  }

  getHandleCopy = showToast => () => {
    const inputElement = this.input.current
    inputElement.select()
    document.execCommand('copy')
    showToast({
      message: <FormattedMessage id="admin/copyOnlyInput.copiedSuccessfully" />,
      duration: TOAST_DURATION,
    })
  }

  render() {
    const { value } = this.props
    return (
      <div className="flex">
        <textarea
          className="pv4 ph6 ba bw1 br--left br2 b--muted-3 w-100"
          type="text"
          value={value}
          ref={this.input}
          readOnly
          style={{
            outlineColor: 'transparent',
            outlineStyle: 'none',
            resize: 'none',
          }}
          autoComplete="off"
        />
        <ToastConsumer>
          {({ showToast }) => (
            <div
              onClick={this.getHandleCopy(showToast)}
              className="bg-muted-5 ph5 pv4 bt br bb bw1 br--right br2 tc
							b--muted-3 pointer v-mid t-action--small c-action-primary">
              <span className="c-link">
                <IconCopy color="currentColor" />
              </span>
              <FormattedMessage id="admin/copyOnlyInput.copyMessage" />
            </div>
          )}
        </ToastConsumer>
      </div>
    )
  }
}

CopyOnlyInput.propTypes = {
  value: PropTypes.string,
}

export default CopyOnlyInput
