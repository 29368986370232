import React from 'react'
import { Spinner } from 'vtex.styleguide'

const FullPageLoading = () => (
  <div className="flex items-center-ns pt11 pt0-ns justify-center aspect-ratio--object bg-white o-70">
    <span className="c-emphasis">
      <Spinner color="currentColor" size={24} />
    </span>
  </div>
)

export default FullPageLoading
