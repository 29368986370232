import React from 'react'
import { FormattedMessage } from 'react-intl'

import AppleIcon from '../icons/AppleIcon'
import ProviderButton from './ProviderButton'

const AppStoreDownloadButton = props => (
  <ProviderButton icon={AppleIcon} {...props}>
    <FormattedMessage id="admin/appStoreDownloadButton" />
  </ProviderButton>
)

export default AppStoreDownloadButton
