import React from 'react'
import { Button } from 'vtex.styleguide'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import AppStoreDownloadButton from '../shared/AppStoreDownloadButton'
import GooglePlayDownloadButton from '../shared/GooglePlayDownloadButton'

const GetAuthApp = ({ onContinue }) => (
  <React.Fragment>
    <div>
      <div className="t-heading-4 c-on-base mb5 lh-copy">
        <FormattedMessage id="admin/downloadGoogleAuthApp.title" />
      </div>
      <div className="t-body-ns t-small c-muted-1 lh-copy">
        <FormattedMessage id="admin/downloadGoogleAuthApp.text" />
      </div>
    </div>
    <div className="mt7">
      <div className="mb4">
        <AppStoreDownloadButton
          onClick={() =>
            window.open(
              'https://itunes.apple.com/us/app/google-authenticator/id388497605',
              '_blank',
              'noopener'
            )
          }
        />
      </div>
      <GooglePlayDownloadButton
        onClick={() =>
          window.open(
            'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
            '_blank',
            'noopener'
          )
        }
      />
    </div>
    <div className="mt7 mb5">
      <Button block variation="primary" onClick={onContinue}>
        <div data-testid="get-auth-app-continue">
          <FormattedMessage id="admin/button.continue" />
        </div>
      </Button>
    </div>
  </React.Fragment>
)

GetAuthApp.propTypes = {
  onContinue: PropTypes.func.isRequired,
}

export default GetAuthApp
