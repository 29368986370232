import React from 'react'
import { Button } from 'vtex.styleguide'
import { FormattedMessage } from 'react-intl'

const SessionExpired = () => {
  return (
    <React.Fragment>
      <div className="mb6-ns mb10 vh-50 h-auto-ns">
        <div className="t-heading-4 c-on-base mb5">
          <FormattedMessage id="admin/sessionExpired.title" />
        </div>
        <div className="t-body-ns t-small c-muted-1 lh-copy">
          <FormattedMessage id="admin/sessionExpired.body" />
        </div>
      </div>
      <Button
        variation="primary"
        block
        onClick={() => window.location.reload.call(location)}>
        <FormattedMessage id="admin/sessionExpired.goBack" />
      </Button>
    </React.Fragment>
  )
}

export default SessionExpired
