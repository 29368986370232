import React, { useMemo } from 'react'
import { Spinner } from 'vtex.styleguide'

import { NOOP } from '../../commons/constants'

const BorderlessButton = ({
  children,
  onClick = NOOP,
  disabled: disabledProp = false,
  loading = false,
}) => {
  const disabled = useMemo(() => disabledProp || loading, [
    disabledProp,
    loading,
  ])

  return (
    <button
      className={`${
        disabled ? 'c-muted-2' : 'c-link hover-c-link active-c-link pointer'
      } pa0 bg-transparent bn relative`}
      disabled={disabled}
      onClick={onClick}>
      {loading && (
        <span className="absolute top-0 left-0 h-100 w-100 flex justify-center items-center c-link">
          <Spinner color="currentColor" size={14} />
        </span>
      )}
      <span className={loading ? 'o-0' : ''}>{children}</span>
    </button>
  )
}

export default BorderlessButton
