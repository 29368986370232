import React from 'react'
import { AuthState, AuthService, validations } from 'vtex.react-vtexid'
import { Button, InputPassword } from 'vtex.styleguide'
import { Value } from 'react-values'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import PropTypes from 'prop-types'

import { PasswordRequirement } from '../shared'
import joinErrorMessages from '../utils/joinErrorMessages'
import useGetPasswordErrors from '../utils/useGetPasswordErrors'
import getIntlError from '../utils/getIntlError'

const messages = defineMessages({
  separator: {
    id: 'admin/passwordLogin.separator',
    defaultMessage: '',
  },
  lastSeparator: {
    id: 'admin/passwordLogin.lastSeparator',
    defaultMessage: '',
  },
})

const { validatePassword } = validations

const SetPassword = ({ onPasswordSet, setAlertError }) => {
  const getPasswordErrors = useGetPasswordErrors()
  const { formatMessage } = useIntl()

  return (
    <div data-testid="set-password">
      <AuthState.Email>
        {({ value: email }) => (
          <div>
            <div className="t-heading-4 c-on-base mb5">
              <FormattedMessage id="admin/setPassword.createYourPassword" />
            </div>
            <div className="t-small t-body-ns c-on-base lh-copy">{email}</div>
          </div>
        )}
      </AuthState.Email>

      <AuthState.Password>
        {({ value: password, setValue }) => (
          <Value defaultValue="">
            {({
              value: errorMessage,
              set: setErrorMessage,
              reset: removeErrorMessage,
            }) => {
              const {
                passwordIsValid,
                hasMinLength,
                hasNumber,
                hasUpperCaseLetter,
                hasLowerCaseLetter,
                hasLetter,
              } = validatePassword(password || '')

              return (
                <div className="dib mt6 w-100" data-testid="password-input">
                  <InputPassword
                    name="password"
                    id="forgotpass--pass"
                    size="large"
                    label={
                      <FormattedMessage id="admin/passwordLogin.inputLabel.password" />
                    }
                    value={password || ''}
                    onChange={e => {
                      removeErrorMessage()
                      setValue(e.target.value)
                    }}
                    errorMessage={errorMessage}
                  />
                  <div className="mv4">
                    <AuthState.IdentityProviders>
                      {({
                        value: {
                          password: isProviderPasswordActive,
                          accessKey: isProviderAccessKeyActive,
                        },
                      }) => {
                        const hasProviderAllActive =
                          isProviderPasswordActive && isProviderAccessKeyActive
                        return (
                          <AuthService.SetPasswordAfterLogin
                            onSuccess={onPasswordSet}
                            onFailure={error =>
                              setAlertError(getIntlError(error.code))
                            }
                            disableSetPreference={!hasProviderAllActive}>
                            {({ loading, action: setPasswordAfterLogin }) => (
                              <Button
                                variation="primary"
                                block
                                isLoading={loading}
                                onClick={() => {
                                  if (passwordIsValid) {
                                    setPasswordAfterLogin()
                                  } else {
                                    setErrorMessage(
                                      <FormattedMessage
                                        id="admin/passwordLogin.inlinePasswordError"
                                        values={{
                                          errors: joinErrorMessages(
                                            getPasswordErrors(password),
                                            formatMessage(messages.separator),
                                            formatMessage(
                                              messages.lastSeparator
                                            )
                                          ),
                                        }}
                                      />
                                    )
                                  }
                                }}>
                                <div data-testid="create-password">
                                  <FormattedMessage id="admin/setPassword.buttton.createPassword" />
                                </div>
                              </Button>
                            )}
                          </AuthService.SetPasswordAfterLogin>
                        )
                      }}
                    </AuthState.IdentityProviders>
                  </div>
                  <PasswordRequirement
                    hasEightCharacters={hasMinLength}
                    hasNumber={hasNumber}
                    hasUppercaseLetter={hasUpperCaseLetter}
                    hasLowercaseLetter={hasLowerCaseLetter}
                    hasLetter={hasLetter}
                  />
                </div>
              )
            }}
          </Value>
        )}
      </AuthState.Password>
    </div>
  )
}

SetPassword.propTypes = {
  onPasswordSet: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
}

export default SetPassword
