import React from 'react'
import PropTypes from 'prop-types'

import { TabbableInput } from './shared'

const EmailInput = ({
  onChangeEmail,
  email,
  setShowError,
  onSubmitEmail,
  errorMessage,
  label,
}) => (
  <div className="mb4">
    <TabbableInput
      size="large"
      type="email"
      label={label}
      name="email"
      id="email"
      errorMessage={errorMessage}
      value={email || ''}
      onChange={e => {
        onChangeEmail(e.target.value)
        setShowError(false)
      }}
      onEnter={onSubmitEmail}
    />
  </div>
)

EmailInput.propTypes = {
  onChangeEmail: PropTypes.func.isRequired,
  email: PropTypes.string,
  setShowError: PropTypes.func.isRequired,
  onSubmitEmail: PropTypes.func.isRequired,
  errorMessage: PropTypes.node,
  label: PropTypes.node,
}

export default EmailInput
