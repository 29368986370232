import React, { Fragment } from 'react'
import { AuthState, AuthService, validations } from 'vtex.react-vtexid'
import { Button, InputPassword } from 'vtex.styleguide'
import { Value } from 'react-values'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import PropTypes from 'prop-types'

import { PasswordRequirement } from '../shared'
import joinErrorMessages from '../utils/joinErrorMessages'
import useGetPasswordErrors from '../utils/useGetPasswordErrors'
import getIntlError from '../utils/getIntlError'

const messages = defineMessages({
  separator: {
    id: 'admin/passwordLogin.separator',
    defaultMessage: '',
  },
  lastSeparator: {
    id: 'admin/passwordLogin.lastSeparator',
    defaultMessage: '',
  },
})

const { validatePassword } = validations

const UpdateExpiredPassword = ({ onPasswordUpdated, setAlertError }) => {
  const getPasswordErrors = useGetPasswordErrors()
  const { formatMessage } = useIntl()

  return (
    <Fragment>
      <div>
        <div className="t-heading-4 c-on-base mb5">
          <FormattedMessage id="admin/updatePassword.createNewPassword" />
        </div>
        <div className="t-body-ns t-small c-muted-1 lh-copy">
          <FormattedMessage id="admin/updatePassword.passwordExpired" />
        </div>
      </div>

      <AuthState.Password>
        {({ value: password, setValue }) => (
          <Value defaultValue="">
            {({
              value: errorMessage,
              set: setErrorMessage,
              reset: removeErrorMessage,
            }) => {
              const {
                passwordIsValid,
                hasMinLength,
                hasNumber,
                hasUpperCaseLetter,
                hasLowerCaseLetter,
                hasLetter,
              } = validatePassword(password || '')

              return (
                <div className="dib mt6 w-100">
                  <InputPassword
                    name="password"
                    id="forgotpass--pass"
                    size="large"
                    label={
                      <FormattedMessage id="admin/udpatePassword.inputLabel.password" />
                    }
                    value={password || ''}
                    onChange={e => {
                      removeErrorMessage()
                      setValue(e.target.value)
                    }}
                    errorMessage={errorMessage}
                  />
                  <div className="mv4">
                    <AuthService.SetPassword
                      onSuccess={onPasswordUpdated}
                      onFailure={error => {
                        setAlertError(getIntlError(error.code))
                      }}>
                      {({ loading, action: updatePassword }) => (
                        <Button
                          variation="primary"
                          block
                          isLoading={loading}
                          onClick={() => {
                            if (passwordIsValid) {
                              updatePassword()
                            } else {
                              setErrorMessage(
                                <FormattedMessage
                                  id="admin/passwordLogin.inlinePasswordError"
                                  values={{
                                    errors: joinErrorMessages(
                                      getPasswordErrors(password),
                                      formatMessage(messages.separator),
                                      formatMessage(messages.lastSeparator)
                                    ),
                                  }}
                                />
                              )
                            }
                          }}>
                          <FormattedMessage id="admin/updatePassword.buttton.createPassword" />
                        </Button>
                      )}
                    </AuthService.SetPassword>
                  </div>
                  <PasswordRequirement
                    hasEightCharacters={hasMinLength}
                    hasNumber={hasNumber}
                    hasUppercaseLetter={hasUpperCaseLetter}
                    hasLowercaseLetter={hasLowerCaseLetter}
                    hasLetter={hasLetter}
                  />
                </div>
              )
            }}
          </Value>
        )}
      </AuthState.Password>
    </Fragment>
  )
}

UpdateExpiredPassword.propTypes = {
  onPasswordUpdated: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
}

export default UpdateExpiredPassword
