import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'vtex.styleguide'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'

import resizeQrCode from './resizeQrCode'

const messages = defineMessages({
  imageAlt: {
    id: 'admin/setUpGoogleAuthApp.imageAlt',
    defaultMessage: '',
  },
})

const QrCodeAuthSetup = ({ qrCodeUrl, loading, error }) => {
  const resizedQrCode = qrCodeUrl && resizeQrCode(qrCodeUrl)
  const [loadingImage, setLoadingImage] = useState(true)
  const intl = useIntl()

  const isLoading = (loadingImage && !error) || loading

  return (
    <React.Fragment>
      <div>
        <div className="t-heading-4 c-on-base mb5 lh-copy">
          <FormattedMessage id="admin/setUpGoogleAuthApp.qrcode.title" />
        </div>
        <div className="t-body-ns t-small c-muted-1 lh-copy mb7">
          <FormattedMessage id="admin/setUpGoogleAuthApp.qrcode.explanation" />
        </div>
        <div className="flex justify-center">
          {isLoading && (
            <div className="mv8">
              <Spinner />
            </div>
          )}
          {qrCodeUrl && (
            <img
              alt={intl.formatMessage(messages.imageAlt)}
              src={resizedQrCode}
              className={`${loadingImage ? 'dn' : ''}`}
              height={140}
              width={140}
              onError={() => setLoadingImage(false)}
              onLoad={() => setLoadingImage(false)}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

QrCodeAuthSetup.propTypes = {
  error: PropTypes.object,
  qrCodeUrl: PropTypes.string,
}

export default QrCodeAuthSetup
