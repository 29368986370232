import React, { useMemo } from 'react'
import { AuthService } from 'vtex.react-vtexid'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import LoadingWithMessage from './LoadingWithMessage'
import getIntlError from './utils/getIntlError'

const OauthRedirect = ({ setAlertError, onOauthLoginFailure }) => {
  const errorFallbackUrl = useMemo(
    () => window && window.location && window.location.href,
    []
  )

  return (
    <React.Fragment>
      <AuthService.OAuthRedirect
        autorun
        useNewSession
        onFailure={error => {
          onOauthLoginFailure()
          setAlertError(getIntlError(error.code))
        }}
        provider="google"
        errorFallbackUrl={errorFallbackUrl}>
        {() => (
          <div data-testid="oauth-redirect">
            <LoadingWithMessage
              bottomMessage={
                <FormattedMessage
                  id="admin/providerLogin.redirectMessage"
                  values={{ provider: 'Google' }}
                />
              }
            />
          </div>
        )}
      </AuthService.OAuthRedirect>
    </React.Fragment>
  )
}

export default OauthRedirect

OauthRedirect.propTypes = {
  onOauthLoginFailure: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
}
